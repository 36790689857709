header {
  background: #F2F2F2;
  border-bottom: 1px solid rgba(204, 204, 204, 0.25);
  height: 65px;
  padding: 15px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /*
  .menuClose
  {
      position:absolute;
      top:$grid-gutter-width/4;
      right:$grid-gutter-width/2;
      cursor:pointer;
      svg *
      {
          fill:$black;
      }
  }*/
}
header .logo {
  position: relative;
  z-index: 1024;
  cursor: pointer;
  height: 100%;
}
header .logo svg {
  height: 100%;
  width: auto;
}
header .hamburger {
  position: relative;
  z-index: 1024;
  width: 20px;
  cursor: pointer;
}
header .hamburger svg {
  width: 100%;
  height: auto;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-transform: none;
          transform: none;
}
header .hamburger path {
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
header .headerContent {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  padding: 60px 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1023;
  background: #F2F2F2;
}
@media (min-width: 992px) {
header {
    height: 85px;
}
header .headerContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    position: relative;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
}
header .menuClose,
header .hamburger {
    display: none;
}
}
body.menuOpen {
  overflow: hidden;
}
body.menuOpen.transitionIn .headerContent, body.menuOpen.transitionOut .headerContent {
  opacity: 0;
  -webkit-transform: translateY(1rem);
          transform: translateY(1rem);
}
body.menuOpen .headerContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: none;
          transform: none;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}
body.menuOpen .hamburger svg {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
body.menuOpen .hamburger svg path:nth-child(2) {
  -webkit-transform: translateY(13px) translateX(-10px) rotate(45deg);
          transform: translateY(13px) translateX(-10px) rotate(45deg);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
body.menuOpen .hamburger svg path:nth-child(1) {
  opacity: 0;
}
body.menuOpen .hamburger svg path:nth-child(3) {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: translateY(-21px) rotate(-45deg) translateX(-15px);
          transform: translateY(-21px) rotate(-45deg) translateX(-15px);
}